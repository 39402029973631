import request from '../utils/request'
import subRequest from '../utils/subjectRequest'
// 试题列表
export function getList(params) {
  return request({
    // url: '/openapi/newtk/getPartList',
    url: '/exam/zkbwx/partList',
    method: 'get',
    params
  })
}
// 排行榜
export function getRank(params) {
  return request({
    // url: '/openapi/newtk/newtkRank',
    url: '/exam/zkbwx/newtkRank',
    method: 'get',
    params
  })
}

// 查看结果
export function getResultInfo(params) {
  return request({
    // url: '/openapi/newtk/getResultInfo',
    url: '/exam/zkbwx/getResultInfo',
    method: 'get',
    params
  })
}
// 获取uid
export function getH5Uid(params) {
  return request({
    url: '/exam/zkbwx/getH5Uid',
    method: 'get',
    params
  })
}
// 获取科目
export function getSubjectApi(params) {
  return subRequest({
    url: "/openapi/newtk/getNewExamSubject",
    method: "get",
    params
  })
}
// 获取积分兑换数据
export function getjifenApi(params){
  return request({
    url: "/exam/zkbwx/getMyScore",
    method: "post",
    params
  })
}
