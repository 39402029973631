<template>
  <div ref="page_box" v-title :class="weixin ||'list_page'" data-title="注考帮模考大赛">
    <div v-if="!weixin" ref="top_box" class="header_box">
      <div style="position: relative;">
        <img src="../../assets/back_arrow.png" alt="" class="arrow" @click="goApp">
        筑考帮模考大赛
      </div>
    </div>
    <div v-if="!weixin" class="ad_box">
      <img :src="adInfo.ad_img_url" alt="">
    </div>
    <div v-if="weixin" class="tab_bar">
      <van-tabs v-model="active" :line-width="55" title-inactive-color="#999999" title-active-color="#EE2E2E" @click="switchTab">
        <van-tab v-for="t in subjectList" :key="t.sub_id" :title="t.sub_name" :name="t.sub_id"></van-tab>
      </van-tabs>
      <hr class="tab_bar_hr">
      <!-- <div v-if="subjectList[0]" class="tab_grid"> -->
      <div v-if="false" class="tab_grid">
        <van-grid :column-num="5" :border="false">
          <van-grid-item v-for="(t,i) in menuList" :key="i" @click="switchGrid(i)">
            <van-image width="41" height="41" :src="t.img_url" />
            <span>{{ t.title }}</span>
          </van-grid-item>
        </van-grid>
      </div>
      <div class="tab_footer"></div>
    </div>
    <div class="list_box">
      <template v-for="(item,index) in list">
        <div :key="index" class="list_item">
          <div class="item_top">
            <div class="left_msg">
              <img class="time" src="../../assets/time.png" alt="">
              {{ item.day }} {{ item.time_time }}
            </div>
            <div class="right_msg" @click="onRank(item)">
              <img class="list" src="../../assets/list.png" alt="">
              排行榜
            </div>
          </div>
          <div class="item_btm">
            <div class="test_left">
              <div class="test_name">{{ item.part_name }}</div>
              <div class="test_peo">参与人数<span style="color:#ee2e2e;marginLeft:5px">{{ item.join_num }}人</span></div>
            </div>
            <div class="test_right">
              <span v-show="item.btn_status=== -1" class="btn tiaozhan" @click="onStart">开始挑战</span>
              <span v-show="item.btn_status===1" class="btn tiaozhan" @click="openMask(item)">开始挑战</span>
              <span v-show="item.btn_status===2" class="btn baogao" @click="onResult(item)">查看报告</span>
            </div>
          </div>
        </div>
      </template>
    </div>
    <div v-show="isMask" class="mask" @touchmove.prevent>
      <div class="mask_main">
        <div class="toast">
          本次竞赛时限为2小时，且交卷后不能重做请您预留好时间
        </div>
        <div class="btn_box">
          <span class="btn btn_left" @click="isMask=false">稍后再做</span>
          <span class="btn btn_right" @click="onReview">立即开始</span>
        </div>
      </div>
    </div>

    <van-dialog v-model="isShowDialog" confirm-button-text="我知道了" show-confirm-button>
      <div class="div_dialog_top">
        <p class="div_dialog_top_p1">{{ score }}</p>
        <p class="div_dialog_top_p2">我的积分</p>
      </div>
      <div class="div_dialog_content">
        <p class="div_dialog_content_p">下载注考帮APP进行兑换</p>
        <div v-for="(item,index1) in giftList" :key="index1" class="div_dialog_content_item" :class="{right_border:index1===0,left_border:index1===1}">
          <van-image
            class="img"
            :src="item.tumb"
          />
          <p class="item_p_title">{{ item.name }}</p>
          <div class="div_item_msg">
            <span class="item_s_jf">{{ item.score }}积分</span>
            <span class="item_s_kc">库存:{{ item.stock }}件</span>
          </div>

        </div>
      </div>
    </van-dialog>
  </div>
</template>

<script>
import { getList, getH5Uid, getSubjectApi, getjifenApi } from '@/api/home'
import wx from 'weixin-js-sdk'
// import Cookies from 'js-cookie'
import isWeixin from '../../utils/weixn'
export default {
  data() {
    return {
      isMask: false,
      uid: '',
      session_id: '', // 'f288klg4iBUdr1yD_p-9kI4F7_mD2W9YN9zFh6h3AzMtwsJuNhRLjXG1WRe4HW3_',
      sub_id: '',
      adInfo: {
        ad_img_url: '',
        ad_attend_url: ''
      },
      list: [],
      itemInfo: {},
      weixin: isWeixin,
      active: 1,
      subjectList: [],
      openid: '',
      exam_id: '',
      menuList: [
        {
          title: '模考大赛',
          img_url: require('../../assets/mkds2x.png')
        },
        {
          title: '历年真题',
          img_url: require('../../assets/lnzt2x.png')
        },
        {
          title: '智能刷题',
          img_url: require('../../assets/znst2x.png')
        },
        {
          title: '我的错题',
          img_url: require('../../assets/wdct2x.png')
        },
        {
          title: '积分兑换',
          img_url: require('../../assets/lpdh2x.png')
        }
      ],
      giftList: [],
      isShowDialog: false,
      score: 0
    }
  },
  created() {
    const url = window.location.href
    if (url.split('?').length > 1) {
      const userInfo = this.weixin ? 'openid' : 'zlid'
      const subjectInfo = this.weixin ? 'exam_id' : 'sub_id'
      this.session_id = this.getQueryString(url, userInfo).includes('#/') ? this.getQueryString(url, userInfo).slice(0, this.getQueryString(url, userInfo).length - 2) : this.getQueryString(url, userInfo)
      this.sub_id = this.getQueryString(url, subjectInfo)
      this.weixin && (this.uid = this.getQueryString(url, 'uid'))
      sessionStorage.setItem('session_id', this.session_id)
      sessionStorage.setItem('sub_id', this.sub_id)
    } else {
      this.session_id = sessionStorage.getItem('session_id')
      this.sub_id = sessionStorage.getItem('sub_id')
    }
    // this.uid = Cookies.get('uid')
    this.getLists()
    this.weixin && this.getSubject()
  },
  mounted() {
    this.setiosTop()
  },
  methods: {
    // 切换宫格
    switchGrid(i) {
      if (this.weixin) {
        if (i === 0) {
          this.$toast('已在本模块内')
        } else if (i === 1) {
          wx.miniProgram.navigateTo({ url: '../loading/loading?index=' + i })
        } else if (i === 2) {
          wx.miniProgram.navigateTo({ url: '../loading/loading?index=' + i })
        } else if (i === 4) {
          this.isShowDialog = true
          const params = {
            uid: this.uid
          }
          getjifenApi(params).then(res => {
            if (res.errNo === 0 || res.errNo === '0') {
              this.giftList = res.result.gift_list
              this.score = res.result.score
            } else {
              this.$toast(res.msg)
            }
          })
        } else if (i === 3) {
          this.$toast('该功能暂未开放')
        }
      }
    },
    // 切换tab
    switchTab(name, title) {
      console.log(name)
      this.sub_id = name
      this.getLists()
    },
    // 获取科目列表
    getSubject() {
      const info = {
        exam_id: this.sub_id, //  1 一建     3 二建
        type: 3
      }
      getSubjectApi(info).then(res => {
        this.subjectList = res.result.sub_list
        this.active = res.result.sub_list[0].sub_id
        // this.sub_id= res.result.sub_list[0].sub_id
        this.sub_id = this.weixin ? res.result.sub_list[0].sub_id : ''
        this.getLists()
      })
    },
    getQueryString(url, param) { // param为要获取的参数名 注:获取不到是为null
      var arr = url.split('?')// 分割域名和参数界限
      if (arr.length > 1) {
        arr = arr[1].split('&')// 分割参数
        for (var i = 0; i < arr.length; i++) {
          var tem = arr[i].split('=') // 分割参数名和参数内容
          if (tem[0] === param) {
            return tem[1]
          }
        }
        return null
      } else {
        return null
      }
    },
    setiosTop() {
      const u = navigator.userAgent
      const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
      if (this.weixin) {
        return false
      }
      if (isiOS) {
        if (u.indexOf(' sms_1 ') !== -1) {
          this.$refs.top_box.style.paddingTop = 40 + 'px'
          this.$refs.page_box.style.paddingTop = 84 + 'px'
        } else {
          this.$refs.top_box.style.paddingTop = 20 + 'px'
          this.$refs.page_box.style.paddingTop = 64 + 'px'
        }
        window.webkit.messageHandlers.vue_Fullscreen.postMessage('1')
      }
    },
    getId(fun) {
      const info = {}
      if (!this.weixin) {
        info.ZLH5SessionID = this.session_id // 'nnsimdde6sejk55406emvrhen0' //
      } else {
        info.openid = this.session_id
      }
      getH5Uid(info).then(res => {
        if (res.errNo === 20001) {
          const u = navigator.userAgent
          const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
          if (this.weixin) {
            return false
          }
          if (isiOS) {
            window.webkit.messageHandlers.vue_appLogin.postMessage('app')
          } else {
            window.android.vue_appLogin()
          }
        } else if (res.errNo === 0) {
          this.uid = res.result.uid
          fun()
        }
      })
    },
    getLists() {
      const info = {
        sub_id: this.sub_id,
        app_type: 1
      }
      if (this.weixin) {
        info.openid = this.session_id
      } else {
        info.ZLH5SessionID = this.session_id
      }
      getList(info).then(res => {
        // console.log(res, '试卷')
        const data = res.result
        this.adInfo.ad_attend_url = data.ad_attend_url
        this.adInfo.ad_img_url = data.ad_img_url
        this.list = data.list
      })
    },
    goApp() {
      const u = navigator.userAgent
      const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
      if (this.weixin) {
        return false
      }
      if (isiOS) {
        window.webkit.messageHandlers.vue_backApp.postMessage('app')
      } else {
        window.android.vue_backApp()
      }
    },
    onRank(ele) {
      const that = this
      function goBank() {
        that.$router.push({
          path: 'ranking',
          query: {
            uid: that.uid,
            id: ele.id
          }
        })
      }
      this.getId(goBank)
      // this.$router.push({
      //   path: 'ranking',
      //   query: {
      //     uid: this.uid,
      //     id: ele.id
      //   }
      // })
    },
    openMask(ele) {
      const that = this
      function childFun() {
        that.isMask = true
        that.itemInfo = ele
      }
      this.getId(childFun)
      // this.isMask = true
      // this.itemInfo = ele
    },
    onStart() {
      this.$toast({
        message: '挑战未开始',
        icon: 'none'
      })
    },
    onReview() {
      this.$router.push({
        path: 'reviewing',
        query: {
          uid: this.uid,
          part_id: this.itemInfo.id
        }
      })
    },
    onResult(ele) {
      const that = this
      function childFun() {
        that.$router.push({
          path: 'testResult',
          query: {
            uid: that.uid,
            result_id: ele.result_id
          }
        })
      }
      this.getId(childFun)
      // this.$router.push({
      //   path: 'testResult',
      //   query: {
      //     uid: this.uid,
      //     result_id: ele.result_id
      //   }
      // })
    }
  }
}
</script>

<style lang="scss" scoped>
.mask{
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,.3);
  position: fixed;
  top: 0;
  left: 0;
  .mask_main{
    width: 600px;
    height: 584px;
    // background: #fff;
    border-radius: 10px;
    position: absolute;
    top: 55%;
    left: 50%;
    transform: translate(-50%,-50%);
    text-align: center;
    font-size: 28px;
    background: url('../../assets/thost.png');
    background-size: 100%;
    .toast{
      padding: 258px 30px 0;
      line-height: 20PX;
      color: #333;
      font-weight: 600;
    }
    .btn_box{
      width: 100%;
      box-sizing: border-box;
      padding: 0 46px;
      position: absolute;
      bottom: 42px;
      .btn{
        display: inline-block;
        width: 216px;
        height: 80px;
        line-height: 80px;
        border-radius: 40px;
        text-align: center;
        border: 1px solid #fa4f21;
        background: #fff;
      }
      .btn_left{
        float: left;
        color: #fa4f21;
      }
      .btn_right{
        float: right;
        color: #fff;
        background:#fa4f21;
      }
    }
  };
}
.ad_box{
  width: 100%;
  height: 400px;
  background: #f6f6f6;
  text-align: center;
  img{
    width: 660px;
    height: 330px;
    margin: 30px auto;
  }
}
.list_page{
  padding-top: 88px;
}
.list_box{
  padding-bottom: 40px;
  .list_item{
    padding: 0px 30px 30px;
    background: #fff;
    border-bottom: 20px solid #f6f6f6;
    .item_top{
      padding: 15px 0;
      border-bottom: 2px solid #f6f6f6;
      color: #999;
      overflow: hidden;
      img{
        vertical-align: middle;
      }
      .time{
        width: 40px;
        height: 40px;
        margin-right: 12px;
        vertical-align: sub;
      }
      .list{
        width: 36px;
        height: 44px;
        margin-right: 12px;
      }
      .left_msg{
        float: left;
      }
      .right_msg{
        float: right;
        right: 20px;
      }
    }
    .item_btm{
      padding-top: 34px;
      overflow: hidden;
      .test_left{
        float: left;
        max-width: 500px;
        .test_name{
          font-size: 14PX;
          margin-bottom: 22px;
          color: #333;
          font-weight: 600;
        }
        .test_peo{
          color: #999;
          font-size: 12PX;
        }
      }
      .test_right{
        float: right;
        right: 30px;
        // top: 40px;
        height: 48px;
        .btn{
          display: inline-block;
          width: 146px;
          height: 48px;
          text-align: center;
          line-height: 48px;
          border-radius: 10px;
          margin-top: 20px;
          border:1px solid #ee2e2e;
        }
        .tiaozhan{
          color: #fff;
          background: #ee2e2e;
        }
        .baogao{
          color: #ee2e2e;
          background-color: #fff;
        }
      }
    }
  }
}

.van-tabs {
  font-size: 14px;
  font-weight: 400;
}

.tab_bar_hr {
    border: none;
    border-bottom: 1px solid #EEEEEE;
}

/deep/ .van-tabs__line {
  transform: translateX(43px) translateX(-50%);
}

/deep/ .van-tabs__wrap--scrollable .van-tab {
    -webkit-box-flex: 1;
    -webkit-flex: 1 0 auto;
    flex: 1 0 auto;
    padding: 0 15px;
}

.tab_grid {
  padding: 0 21px;
  span {
    padding-top: 4px;
  }
}
.tab_footer {
  width: 100%;
  height: 10px;
  background: #EEEEEE;
}

/deep/ .van-dialog {
    width: 624px;
    height: 880px;
    border-radius: 8px;
    background: url(../../assets/dialog_bg_gift.png);
    background-size: 100% 100%;

}

/deep/.van-dialog__footer{
    background: white;
}

/deep/.van-dialog__confirm{
    width: 534px;
    height: 88px;
    background: #EE2E2E;
    border-radius: 8px;
    margin: 30px auto;
    font-size: 28px;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 88px;
    flex:none

}

.div_dialog_top{
      padding-top: 90px;
      margin: 0 auto;
      width: 342px;
      height: 176px;
      .div_dialog_top_p1{
          height: 100px;
          text-align: center;
          font-size: 72px;
          font-weight: 500;
          color: #FFFFFF;
          line-height: 100px;
      }
      .div_dialog_top_p2{
          font-size: 24px;
          text-align: center;
          font-weight: 400;
          color: #FFFFFF;
          line-height: 34px;
      }
  }
  .div_dialog_content{
      margin: 36px auto 0px auto;
      height: 440px;
      text-align: center;
      border: none;
      .div_dialog_content_p{
          height: 44px;
          text-align: center;
          background: #FFFFFF;
          font-size: 32px;
          font-weight: 500;
          color: #333333;
          line-height: 44px;
      }
      .div_dialog_content_item{
          padding: 0px 20px 0px 30px;
          margin: 26px auto 0 auto;
          display: inline-block;
          height: 368px;
          width: 254px;
      }
      .right_border{
          border-right: #EEEEEE 3px solid;
      }
      .left_border{
          border-left: #EEEEEE 3px solid;
      }
      .img{
          width: 254px;
          height: 238px;
      }
      .item_p_title{
          text-align: left;
          height: 56px;
          font-size: 24px;
          font-weight: 400;
          color: #333333;
          line-height: 28px;
      }
      .div_item_msg{
          display: flex;
          justify-content: space-between;
          .item_s_jf{
              height: 28px;
              font-size: 24px;
              font-weight: 400;
              color: #EE2E2E;
              line-height: 28px;
          }
          .item_s_kc{
              height: 28px;
              font-size: 24px;
              font-weight: 400;
              color: #999999;
              line-height: 28px;
          }
      }
    }
</style>
